<template>
  <div class="Homec">
    <!-- 四个导航按钮 定位  -->
    <div class="navigationButtons">
      <rightNavigation :showReport="navigationHidden"></rightNavigation>
    </div>
    <!-- 滚动 -->
    <div class="rollbuy">
      <div class="xxdw">
        <rollbuy></rollbuy>
      </div>
    </div>
    <!--填写个人信息区域 info -->
    <div class="info">
      <div class="title">
        <img src="../assets/Home3/xin-toptit.png" alt="" />
        <span>借贷风险评估</span>
      </div>
      <div class="inputbox">
        <div class="imgdiv usernamediv"></div>
        <input
          type="text"
          placeholder="输入您的真实姓名"
          maxlength="20"
          v-model="userName"
        />
      </div>
      <div class="inputbox">
        <div class="imgdiv phonediv"></div>
        <input
          type="text"
          placeholder="输入您的手机号"
          maxlength="11"
          v-model="userPhone"
        />
      </div>
      <div class="inputbox">
        <div class="imgdiv useriddiv"></div>
        <input
          type="text"
          placeholder="输入您的身份证号"
          maxlength="18"
          v-model="userId"
        />
      </div>
      <!-- 协议1 -->
      <div class="agreement">
        <van-checkbox v-model="agreementChecked" icon-size="14px" />
        <span style="color: 666; margin-left: 10px">我已同意并阅读</span>
        <span style="color: #ffb300" @click="gotoprotocol"
          >《报告查询服务协议》</span
        >
      </div>
      <!-- 协议2 -->
      <div class="agreement" v-if="bestowal != -1 && bestowalContent">
        <van-checkbox v-model="agreementChecked2" icon-size="14px" />
        <!-- <span v-if="bestowal == 2" style="color: 666; margin-left: 10px">{{
          content
        }}</span> -->
        <!--    v-if="
            bestowal == 0 || bestowal == 1 || bestowal == 3 || bestowal == 4
          " -->
        <div
          class="twoAgreement"
          style="color: 666; margin-left: 10px"
          v-if="bestowalContent"
        >
          {{ bestowalContent }}
          <span
            style="color: #ffb300"
            @click="insuranceProtocol"
            v-if="agreementName"
            >《{{ agreementName }}》</span
          >
        </div>
      </div>

      <!-- 立即打赏查询按钮 -->
      <div class="queryBtn">
        <van-button type="info" round @click="toPayBtn">{{
          textBtn
        }}</van-button>
      </div>
      <!-- 报告样例 查询记录 -->
      <div class="sample">
        <div @click="sampleBtn">
          <img src="../assets/Home3/link1.png" alt="" />
          <span>报告样例</span>
        </div>
        <div @click="myReport">
          <img src="../assets/Home3/link2.png" alt="" />
          <span>我的报告</span>
        </div>
      </div>
    </div>
    <!-- 八个模块 -->
    <div class="hitQuery">
      <div class="hitTitle">
        <img src="../assets/Home3/xin-tit.png" alt="" />
        <span>专业版检测内容</span>
        <img src="../assets/Home3/xin-tit1.png" alt="" />
      </div>
      <div class="testModular">
        <div class="item">
          <img src="../assets/Home2/1.png" alt="" />
          <span>背景优化建议</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/2.png" alt="" />
          <span>背景知识普及</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/3.png" alt="" />
          <span>债务纠纷问题</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/4.png" alt="" />
          <span>优化技巧指南</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/5.png" alt="" />
          <span>黑名单风险排查</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/6_7.png" alt="" />
          <span>风险信息扫描</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/6_7.png" alt="" />
          <span>欺诈风险监控</span>
        </div>
        <div class="item">
          <img src="../assets/Home2/8.png" alt="" />
          <span>司法失信咨询</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast } from 'vant';
Vue.use(Toast);

import rightNavigation from '../components/rightNavigation.vue';
import rollbuy from '../components/rollbuy.vue';

import { getUserInfo, button } from '../api/getUserInfo';

export default {
  name: 'Homec',
  components: {
    rightNavigation,
    rollbuy,
  },
  data() {
    return {
      navigationHidden: {
        // 报告样例
        reportSample: {
          show: false,
          styleCss: '',
        },
        // 我的报告
        myReport: {
          show: true,
          styleCss:
            'background:#607df9;box-shadow: 0 0 10px 0 rgb(136 204 247);opacity:0.8',
        },
        // 客服
        service: {
          show: true,
          styleCss:
            'background:#f97f4a;box-shadow: 0 0 10px 0 #f97f4a;opacity:0.8',
        },
        // 建议
        suggest: {
          show: true,
          styleCss:
            'background: #43a7fb;;box-shadow: 0 0 3px 0 rgb(136 204 247);opacity:0.8',
        },
      },
      // 是否勾选协议1
      agreementChecked: true,
      // 是否勾选协议2
      agreementChecked2: false,
      // 按钮文字
      textBtn: '立即查询',
      bestowal: '3', //赠险类型
      // 手机号
      userPhone: null,
      // 用户名
      userName: null,
      // 用户身份证
      userId: null,
      // 产品ID
      cid: null,
      // 模板ID
      templateId: 3,
      bestowalContent: '本人自愿免费领取价值999元个人债务规划方案',
      agreementName: '',
      isStop: false,
    };
  },
  created() {
    this.cid = this.$route.query.cid;
    this.templateId = this.$route.query.templateId;
  },
  mounted() {
    // 执行按钮文案
    this.buttonTxt({ cid: this.cid });
  },
  methods: {
    // 三个协议
    gotoprotocol() {
      this.$router.push({
        path: '/queryProtocol',
      });
    },
    insuranceProtocol() {
      this.$router.push({
        path: '/insuranceProtocol',
        query: {
          cid: this.cid,
        },
      });
    },
    userProtocol() {
      this.$router.push({
        path: '/userProtocol',
      });
    },
    // 报告样例
    sampleBtn() {
      if (this.isStop) {
        return Toast("本系统暂停运营，请及时联系商务注册使用最新的系统！");
      }
      this.$router.push({
        path: '/example',
      });
    },
    // 我的报告
    myReport() {
      if (this.isStop) {
        return Toast("本系统暂停运营，请及时联系商务注册使用最新的系统！");
      }
      this.$router.push({
        path: '/Reportquery',
      });
    },
    // 立即查询
    toPayBtn() {
      if (this.isStop) {
        return Toast("本系统暂停运营，请及时联系商务注册使用最新的系统！");
      }
      let Toast1 = Toast.loading({
        duration: 0,
        message: '查询中...',
        forbidClick: true,
      });
      // 身份证号正则
      let _IDRe18 =
        /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let _IDre15 =
        /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
      // 手机号正则
      let reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      var namereg = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
      if (!namereg.test(this.userName) || !this.userName) {
        return Toast('请正确填写您的姓名');
      }
      if (!reg.test(this.userPhone)) {
        return Toast('请填写正确的手机号码');
      }
      if (!this.agreementChecked) {
        return Toast('请同意《报告查询服务协议》');
      }
      if (_IDRe18.test(this.userId) || _IDre15.test(this.userId)) {
        // 这里写逻辑
        let cdata = {
          username: this.userName,
          mobile: this.userPhone,
          idCard: this.userId,
          bestowal: this.agreementChecked2,
          cid: this.cid,
        };
        getUserInfo(cdata)
          .then((res) => {
            if (res.data.code == 200) {
              Toast1.clear();
              // status true 正常用户 false 用户已拉黑
              if (res.data.data.status) {
                // orderNo 存在 24小时内已经购买过的 去详情
                if (res.data.data.orderNo == null) {
                  // 未支付的 去支付
                  this.$router.push({
                    path: '/toBePaid',
                    query: {
                      userId: res.data.data.userId,
                      cid: this.cid,
                      idCard: this.userId,
                      userName: this.userName,
                    },
                  });
                } else {
                  this.$router.push({
                    path: '/prosperity',
                    query: {
                      orderNo: res.data.data.orderNo,
                      cid: this.cid,
                      templateId: this.templateId,
                    },
                  });
                }
              } else {
                //  用户已拉黑
                Toast(res.data.msg);
              }
            } else {
              Toast(res.data.msg);
            }
          })
          .catch((err) => {
            Toast(err);
          });
      } else {
        return Toast('身份证填写有误');
      }
    },
    buttonTxt(data) {
      button(data)
        .then((res) => {
          if (res.data.code == 200) {
            this.textBtn = res.data.data.buttonText || '立即查询';
            this.bestowal = res.data.data.bestowalType;
            this.bestowalContent = res.data.data.bestowalContent;
            this.agreementName = res.data.data.agreementName;
          } else {
             //按钮禁用
             this.isStop = true;
            this.navigationHidden = {
              reportSample: {
                show: false,
                styleCss: "",
              },
              // 我的报告
              myReport: {
                show: false,
                styleCss: "",
              },
              // 客服
              service: {
                show: true,
                styleCss: "",
              },
              // 建议
              suggest: {
                show: false,
                styleCss: "",
              },
            };
            Toast(res.data.msg);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.Homec {
  max-width: 750px;
  min-height: 100vh;
  margin: 0 auto;
  background-image: url('../assets/Home3/wh-banner-twod1.png');
  background-repeat: no-repeat !important;
  background-position: 0 0 !important;
  background-size: contain !important;
  background-color: #f9f9f9;
  padding-bottom: 50px;
  // 右边三个导航
  .navigationButtons {
    position: fixed;
    top: 550px;
    right: 0;
    z-index: 1;
  }
  // 滚动购买
  .rollbuy {
    width: 100%;
    height: 450px;
    position: relative;
    .xxdw {
      min-width: 360px;
      height: 80px;
      overflow: hidden;
      position: absolute;
      top: 35px;
      right: 0;
    }
  }
  // 填写信息区域 info
  .info {
    width: calc(100% - 50px);
    min-height: 556px;
    background: #fff;
    border-radius: 15px;
    margin: 0 auto;
    box-shadow: 0 10px 20px 0 rgb(228, 228, 228);
    .title {
      height: 46px;
      padding: 27px 0;
      position: relative;
      text-align: center;
      img {
        width: 200px;
        height: 46px;
      }
      span {
        position: absolute;
        top: 30px;
        left: 280px;
        font-size: 24px;
        font-weight: 600;
        color: #fff;
      }
    }
    .inputbox {
      width: 92%;
      height: 66px;
      padding: 13px 0;
      // background: #f0f0f0;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      border-radius: 10px;
      margin-bottom: 20px;
      border: 1px solid #f3f3f3;
      .imgdiv {
        width: 66px;
        height: 66px;
        background-size: 66px 393px;
        background-image: url(../assets/Home3/wh-logo.png);
      }
      .usernamediv {
        background-position: 10px 10px;
      }
      .phonediv {
        background-position: 18px -140px;
      }
      .useriddiv {
        background-position: 10px -67px;
      }

      input {
        width: calc(100% - 100px);
        height: 66px;
        // background:#f0f0f0;
        border: none;
        font-size: 26px;
      }
    }
    // 协议样式 开始
    .agreement {
      width: 92%;
      // height: 30px;
      margin: 10px auto;
      /* background: skyblue; */
      display: flex;
      align-items: center;
      font-size: 24px;
    }
    .agreement1 {
      width: 92%;
      display: flex;
      margin: 20px auto;
    }
    .twoAgreement {
      width: 92%;
      // height: 50px;
      text-align: left;
    }
    // 协议样式 结束
    .queryBtn {
      width: 100%;
      height: 120px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 30px;
      margin-bottom: 40px;
      button {
        width: 92%;
        height: 88px;
        font-size: 32px;
        text-align: center;
        color: #fff;
        cursor: pointer;
        background: linear-gradient(90deg, #f97f4a 20%, #fd5a50 80%);
        box-shadow: 0 5px 8px 0 rgb(251 191 177);
        border: none;
      }
    }
    // 报告样例
    .sample {
      width: 100%;
      height: 120px;
      background: #fefaec;
      display: flex;
      justify-content: space-around;
      border-radius: 0 0 15px 15px;
      div {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
        span {
          color: #757575;
          font-size: 28px;
        }
      }
    }
  }
  // 八个模块
  .hitQuery {
    width: calc(100% - 50px);
    min-height: 380px;
    background: #fff;
    border-radius: 15px;
    margin: 40px auto 0 auto;
    padding-bottom: 20px;
    box-shadow: 0 10px 20px 0 rgb(228, 228, 228);
    .hitTitle {
      width: 100%;
      height: 100px;
      font-size: 32px;
      color: #6db3fd;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 220;
        height: 21px;
      }
      span {
        margin: 0 10px;
      }
    }
    .testModular {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .item {
        height: 88px;
        width: 40%;
        height: 88px;
        color: #777;
        float: left;
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 66px;
          height: 66px;
        }
      }
    }
  }
}
</style>
